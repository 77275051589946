import React, { useEffect } from 'react';

import { isFalsy } from '@leapfinance/frontend-commons';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import { selectUserDetails } from '@/features/user/userSlice';
import { Permissions } from '@/permissions/featuresList';
import { trackClick } from '@/segment/eventTracker';
import { useLazyCheckCspPendingPaymentsQuery } from '@/services/cspPerformanceApi';
import { useGetRewardCreditedEventQuery } from '@/services/rewardCredited';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';
import { localStorageKeys } from '@/utils/GBLocalStorageUtil';
import {
  getAdPopupImageForNov24PaymentDrive,
  shouldShowPopup,
} from '@/utils/newsletterFeatureUtil';

import { MODAL_TYPES } from './Modals/GlobalModal';
import useModal from './Modals/useModal';
import LoanMelaAwarenessPopper from './Tiering/LoanMelaAwarenessPopper';

const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;

const LOAN_MELA_AWARENESS_POPUP_DISBALE_DATE = `2024-11-08 13:00:00`;
const ADPOPUP_NOV_24_PAYMENT_DRIVE_DISABLE_DATE = `2024-11-29 23:59:59`;
const DEC_14_OFFER_STAGE_DISABLE_DATE = `2024-12-14 17:59:59`;
const DEC_13_DUOLINGO_VOUCHER_DISABLE_DATE = `2024-12-13 17:59:59`;

const IELTS_DEMO_POPUP_DISABLE_DATE = `2025-01-08 23:59:59`;

const AnnouncementWrapper = (props: { children: React.ReactNode }) => {
  const { showModal } = useModal();
  const router = useRouter();
  const { data: rewardCreditedEventData } = useGetRewardCreditedEventQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [triggerCheckPendingPayment, { data: pendingPayment }] =
    useLazyCheckCspPendingPaymentsQuery();

  const cspProfileData = useAppSelector((state) => selectUserDetails(state));

  const isInternationalCsp = cspProfileData?.cspZone === `International`;

  const isStudentUser =
    cspProfileData?.permissions?.[0] === Permissions.STUDENT;

  useEffect(() => {
    const checkPendingPayment = async () => {
      if (router?.pathname?.toString() === `/summary-dashboard`) {
        await triggerCheckPendingPayment();
      }
    };

    checkPendingPayment();
  }, []);

  useEffect(() => {
    if (
      isFalsy(cspProfileData?.permissions) ||
      isStudentUser ||
      !router?.isReady
    )
      return;
    const routesWhereToNotShow = [
      `/`,
      `/login`,
      `/changepwd`,
      `/resetpwd`,
      `/test-prep/video-course`,
      `/test-prep/video-course/[slug]`,
      `/test-prep/mock-test`,
      `/test-prep/mock-test/[mock_test_id]`,
      `/test-prep/mock-test/result/[mock_test_id]`,
      `/test-prep/mock-test/speaking/result/[id]`,
      `/ielts`,
      `/student-login`,
    ];

    if (routesWhereToNotShow?.includes(router?.pathname?.toString())) return;

    if (
      rewardCreditedEventData?.data?.includes(`PAYMENT_APPLICATION`) ||
      rewardCreditedEventData?.data?.includes(`STI_APPLICATION`)
    ) {
      showModal({
        modalType: MODAL_TYPES.REWARDS_NOTIFY_MODAL,
        modalProps: {
          type: rewardCreditedEventData?.data?.includes(`PAYMENT_APPLICATION`)
            ? `payment`
            : rewardCreditedEventData?.data?.includes(`STI_APPLICATION`)
            ? `application`
            : null,
        },
      });
    }

    const showNovPaymentDrive = shouldShowPopup({
      key: localStorageKeys.ADPOPUP_NOV_24_PAYMENT_DRIVE,
      skipTimeSlot: false,
      modalDisableDateTime: ADPOPUP_NOV_24_PAYMENT_DRIVE_DISABLE_DATE,
      // this means (11 AM - 3 PM and 3 PM - 7 PM)
      timeSlotStart: [11, 15],
      timeSlotEnd: [15, 19],
      minGapHours: 2,
    });

    const showOfferStageStudentsModal = shouldShowPopup({
      key: localStorageKeys.OFFER_STAGE_STUDENTS_MODAL,
      skipTimeSlot: false,
      modalDisableDateTime: DEC_14_OFFER_STAGE_DISABLE_DATE,
      timeSlotStart: [11, 11],
      timeSlotEnd: [18, 18],
      maxTimesToShowPerDay: 1,
    });

    const showIeltsDemoPopup = shouldShowPopup({
      key: localStorageKeys.IELTS_LIVE_JAN_DRIVE,
      skipTimeSlot: false,
      modalDisableDateTime: IELTS_DEMO_POPUP_DISABLE_DATE,
      minGapHours: 2,
      maxTimesToShowPerDay: 3,
      timeSlotStart: [11, 11],
      timeSlotEnd: [18, 18],
    });

    const showDuolingoVoucherPopup = shouldShowPopup({
      key: localStorageKeys.DUOLINGO_VOUCHER_MODAL,
      skipTimeSlot: false,
      modalDisableDateTime: DEC_13_DUOLINGO_VOUCHER_DISABLE_DATE,
      timeSlotStart: [11, 11],
      timeSlotEnd: [18, 18],
      maxTimesToShowPerDay: 2,
      minGapHours: 3,
    });

    if (
      showNovPaymentDrive &&
      !cspProfileData?.permissions?.includes(`SUB_AGENT`) &&
      !isInternationalCsp &&
      !routesWhereToNotShow?.includes(router?.pathname?.toString())
    ) {
      showModal({
        modalType: MODAL_TYPES.NO_RESULTS,
        modalProps: {
          body: (
            <LoanMelaAwarenessPopper
              redirectionUrl={`https://forms.gle/UU7PyhnTpGCxMCsA6`}
              imageUrl={getAdPopupImageForNov24PaymentDrive().url}
              storageKey={localStorageKeys.ADPOPUP_NOV_24_PAYMENT_DRIVE}
              hideImageClick
              imageClassName={getAdPopupImageForNov24PaymentDrive().className}
              containerClassName={
                getAdPopupImageForNov24PaymentDrive()?.containerClassName ??
                `max-w-[760px]`
              }
            />
          ),
        },
      });
      return;
    }

    if (
      showDuolingoVoucherPopup &&
      cspProfileData?.permissions?.includes(`ADMIN`) &&
      !isInternationalCsp
    ) {
      showModal({
        modalType: MODAL_TYPES.VOUCHER_GTM_MODAL,
        modalProps: undefined,
      });

      return;
    }

    if (
      pendingPayment?.data === true &&
      cspProfileData?.permissions?.includes(`ADMIN`) &&
      router?.pathname?.toString() === `/summary-dashboard` &&
      showOfferStageStudentsModal
    ) {
      showModal({
        modalType: MODAL_TYPES.OFFER_STAGE_STUDENT_MODAL,
        modalProps: undefined,
      });

      return;
    }

    if (
      showIeltsDemoPopup &&
      !cspProfileData?.permissions?.includes(`SUB_AGENT`) &&
      !cspProfileData?.permissions?.includes(`STUDENT`) &&
      !isInternationalCsp &&
      !routesWhereToNotShow?.includes(router?.pathname?.toString())
    ) {
      showModal({
        modalType: MODAL_TYPES.NO_RESULTS,
        modalProps: {
          body: (
            <LoanMelaAwarenessPopper
              redirectionUrl={`https://us02web.zoom.us/j/83445923261?pwd=6H4Dq1DFAPju2HRmbDZX5jMs5839kp.1`}
              imageUrl={`https://ik.imagekit.io/v8frh8b7rf/ielts-live-demo.jpeg?updatedAt=1736234988607`}
              storageKey={localStorageKeys.IELTS_LIVE_JAN_DRIVE}
              containerClassName="max-w-[650px]"
              onImageClick={() => {
                trackClick({
                  pageName: PageNames.IELTS_LIVE_DEMO_POPUP,
                  contentFormat: `Modal`,
                  contentName: `Join Meeting`,
                  widgetFormat: WidgetFormat.MODAL,
                  widgetName: WidgetName.IELTS_LIVE_DEMO_POPUP,
                });
              }}
              onModalClose={() => {
                trackClick({
                  pageName: PageNames.IELTS_LIVE_DEMO_POPUP,
                  contentFormat: `Modal`,
                  contentName: `Close`,
                  widgetFormat: WidgetFormat.MODAL,
                  widgetName: WidgetName.IELTS_LIVE_DEMO_POPUP,
                });
              }}
            />
          ),
        },
      });
      return;
    }
  }, [
    router,
    isInternationalCsp,
    cspProfileData,
    pendingPayment,
    rewardCreditedEventData,
  ]);

  return <>{props.children}</>;
};

export default AnnouncementWrapper;

// Display IELTS adoption popper first
// const ieltsBannerDisplayedDates =
//   GBLocalStorageUtil.handleReadLocalStorageData(
//     localStorageKeys.IELTS_ADOPTION_POPUP_DISPLAYED,
//   ) ?? [];
// const today = new Date()?.toISOString()?.split(`T`)?.[0];
// if (
//   // !routesWhereToNotShow?.includes(router?.pathname?.toString()) &&
//   // !ieltsBannerDisplayedDates?.includes(today) &&
//   // ieltsBannerDisplayedDates?.length < 3
//   false
// ) {
//   showModal({
//     modalType: MODAL_TYPES.NO_RESULTS,
//     modalProps: {
//       body: <IELTSAdoptionPopper />,
//     },
//   });
//   GBLocalStorageUtil.handleSetLocalStorageData(
//     localStorageKeys.IELTS_POPUP_DISPLAYED_TIME,
//     new Date(),
//   );
//   return;
// }
// Display GB Loans modal next only after 2 hours from IELTS popup display
// const showLoansUpsellModal = shouldShowPopup({
//   key: localStorageKeys.SHOW_LOANS_UPSELL_COUNT,
//   maxTimesToShow: 3,
//   skipTimeSlot: true,
// });
// const lastIeltsPopupDisplayedTime =
//   GBLocalStorageUtil?.handleReadLocalStorageData(
//     localStorageKeys.IELTS_POPUP_DISPLAYED_TIME,
//   ) ?? new Date();
// const currentTime = new Date();
// const timeElapsedFromLastIeltsPopupDisplay =
//   (currentTime as any) - (new Date(lastIeltsPopupDisplayedTime) as any);
// if (
//   // showLoansUpsellModal &&
//   // !routesWhereToNotShow?.includes(router?.pathname?.toString()) &&
//   // ![`/rewards-club`]?.includes(router?.pathname?.toString()) &&
//   // !isInternationalCsp &&
//   // timeElapsedFromLastIeltsPopupDisplay > twoHoursInMilliseconds
//   false
// ) {
//   showModal({
//     modalType: MODAL_TYPES.LOANS_REQUEST_UPSELL,
//     modalProps: {
//       onClose: () => {
//         markModalShown(localStorageKeys.SHOW_LOANS_UPSELL_COUNT);
//         hideModal();
//       },
//     },
//   });
//   return;
// }
// Display Commissions modal next
// const showPromotedInstituteModal = shouldShowPopup({
//   key: localStorageKeys.LAST_POPUP_TIMES,
//   maxTimesToShow: 5,
//   skipTimeSlot: false,
// });
// if (showPromotedInstituteModal && isPromoteInstituteBannerVisible) {
//   showModal({
//     modalType: MODAL_TYPES.NEWSLETTER_ANNOUNCEMENT,
//     modalProps: {
//       onClose: () => {
//         markModalShown(localStorageKeys.LAST_POPUP_TIMES);
//         hideModal();
//       },
//     },
//   });
//   return;
// }
