import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { geebeeCfApi } from '@/components/CF/Dashbaord/services/geebeeCf';

interface CourseFinderSlice {
  globalFilterValues: {
    maxCourseFeeInINR: number;
    minCourseFeeInINR: number;
  };
}
const initialState = {
  globalFilterValues: {
    maxCourseFeeInINR: 74893904,
    minCourseFeeInINR: 0,
  },
} as CourseFinderSlice;

export const courseFinderSlice = createSlice({
  name: `courseFinderSlice`,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      geebeeCfApi.endpoints.getCountryCurrencies.matchFulfilled,
      (state, { payload }) => {
        state.globalFilterValues.maxCourseFeeInINR =
          payload.data?.maxValueInr ?? 74893904;
        state.globalFilterValues.minCourseFeeInINR =
          payload.data?.minValueInr ?? 0;
      },
    );
  },
});

export const minMaxInrCurrency = (store: RootState) =>
  store.courseFinderSlice.globalFilterValues;

export default courseFinderSlice.reducer;
