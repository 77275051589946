import dynamic from 'next/dynamic';

const FileViewer = dynamic(() => import(`../FileViewer`), {
  ssr: false,
});

const AddApplication = dynamic(
  () => import(`@/components/Modals/Application/AddApplication`),
  {
    ssr: false,
  },
);

const AddStudentModal = dynamic(() => import(`./AddStudentModal`), {
  ssr: false,
});

const KeyValueDisplayModal = dynamic(
  () => import(`@leapfinance/frontend-commons/components/KeyValueDisplayModal`),
  {
    ssr: false,
  },
);

const ActionCenter = dynamic(
  () => import(`@/components/ActionCenter/ActionCenter`),
  {
    ssr: false,
  },
);

const RequiredDocModal = dynamic(() => import(`./RequiredDocModal`), {
  ssr: false,
});

const NoResults = dynamic(
  () => import(`@leapfinance/frontend-commons/components/NoResults`),
  {
    ssr: false,
  },
);

const ShortlistInformationAndPreferenceModal = dynamic(
  () => import(`./ShortlistInformationAndPreferenceModal`),
  {
    ssr: false,
  },
);

const StudentProfileOnboardingModal = dynamic(
  () => import(`./StudentProfileOnboardingModal`),
  {
    ssr: false,
  },
);

const SimilarCourseModal = dynamic(() => import(`./SimilarCourseModal`), {
  ssr: false,
});

const StudentExamDetailsModal = dynamic(
  () => import(`@/components/StudentOnboardingV2/StudentDetailsModal`),
  { ssr: false },
);

const UpdateCspLogoModal = dynamic(
  () =>
    import(
      `@/components/CSPSettings/SettingsHeader/CspLogoUpdate/UpdateCSPLogoModal`
    ),
  { ssr: false },
);

const RequestVasCallbackModal = dynamic(
  () => import(`@/components/Modals/RequestVasCallbackModal`),
  { ssr: false },
);

const NewsletterFeatureAnnouncementModal = dynamic(
  () => import(`@/components/Modals/NewsletterFeatureAnnouncement`),
  { ssr: false },
);

const AddCourseToShortlist = dynamic(
  () => import(`@/components/Modals/AddCourseToShortlist`),
);

const AddSubAgentForm = dynamic(
  () => import(`@/components/SubAgent/AddSugAgentForm`),
);

const DeferralHistoryModal = dynamic(
  () => import(`@/components/Deferral/DeferralHistoryModal`),
);

const ConfirmDeleteExistingModal = dynamic(
  () =>
    import(`@/components/Modals/StudentOnboarding/ConfirmDeleteExistingModal`),
);

const StudentOnboardingFormPreview = dynamic(
  () =>
    import(
      `@/components/Modals/StudentOnboarding/StudentOnboardingFormPreview`
    ),
);

const GICMarkSuggestionModal = dynamic(
  () => import(`@/components/Modals/vas/GICMarkSuggestionModal`),
  { ssr: false },
);

const RewardCreditedNotifyModal = dynamic(
  () => import(`@/components/Modals/Rewards/RewardCreditedNotifyModal`),
  { ssr: false },
);

export {
  FileViewer,
  AddApplication,
  AddStudentModal,
  KeyValueDisplayModal,
  ActionCenter,
  RequiredDocModal,
  NoResults,
  ShortlistInformationAndPreferenceModal,
  StudentProfileOnboardingModal,
  SimilarCourseModal,
  StudentExamDetailsModal,
  UpdateCspLogoModal,
  RequestVasCallbackModal,
  NewsletterFeatureAnnouncementModal,
  AddCourseToShortlist,
  AddSubAgentForm,
  DeferralHistoryModal,
  ConfirmDeleteExistingModal,
  StudentOnboardingFormPreview,
  GICMarkSuggestionModal,
  RewardCreditedNotifyModal,
};
