import { isMobile } from 'react-device-detect';

import { store } from '@/app/store';
import {
  IdentifyUser,
  LoginStatus,
  LoginType,
  TrackAuthenticationProperties,
  TrackCard,
  TrackClick,
  TrackPageProperties,
} from '@/types/segmentEvents';
import { detectBrowser } from '@/utils/browser';
import { getPageName } from '@/utils/segment';

import { EventNames } from './eventNames';

export const trackPage = (properties: TrackPageProperties) => {
  let prop: TrackPageProperties = {
    browser: detectBrowser(),
    pageUrl: window.location.href || `NA`,
    platform: isMobile ? `MWeb` : `Desktop`,
    ...properties,
    ...properties?.restInfo,
  };
  const state = store.getState();
  if (state && state.user.userConfig) {
    const { userId, cspId } = state.user.userConfig;
    prop = {
      ...properties,
      ...prop,
      userId: userId || `NA`,
      cspId: cspId || `NA`,
    };
  }
  window?.analytics?.page(EventNames.PAGE_VIEW, {
    ...prop,
    screenHeight: window?.screen?.height,
    screenWidth: window?.screen?.width,
  });
};

export const trackEvent = (
  event: string,
  properties?: Record<string, unknown> | undefined,
) => {
  properties = {
    pageName: getPageName(),
    ...properties,
  };
  const state = store.getState();
  if (state && state.user.userConfig) {
    const { userId, cspId } = state.user.userConfig;
    properties = {
      ...properties,
      userId: userId || `NA`,
      cspId: cspId || `NA`,
    };
  }
  window?.analytics?.track(event, properties);
};

export const trackLogin = (payload: TrackAuthenticationProperties) => {
  trackEvent(EventNames.LOGIN, {
    ...payload,
    authenticationType: `Email`,
  });
};

export const trackSignIn = (status: LoginStatus) => {
  const payload: TrackAuthenticationProperties = {
    loginType: LoginType.SIGN_IN,
    loginStatus: status,
  };
  trackLogin(payload);
};

export const trackSignUp = (status: LoginStatus) => {
  const payload: TrackAuthenticationProperties = {
    loginType: LoginType.SIGN_UP,
    loginStatus: status,
  };
  trackLogin(payload);
};

export const trackForgotPassword = (status: LoginStatus) => {
  const payload: TrackAuthenticationProperties = {
    loginType: LoginType.FORGOT_PASSWORD,
    loginStatus: status,
  };
  trackLogin(payload);
};

export const trackResetPassword = (status: LoginStatus) => {
  const payload: TrackAuthenticationProperties = {
    loginType: LoginType.RESET_PASSWORD,
    loginStatus: status,
  };
  trackLogin(payload);
};

export const trackCardAddition = (properties: Partial<TrackCard>) => {
  trackEvent(EventNames.CARD_ADDED, {
    pageUrl: window.location.href || `NA`,
    ...properties,
  });
};

export const trackClick = (properties: Partial<TrackClick>) => {
  trackEvent(EventNames.CLICK, {
    pageUrl: window.location.href || `NA`,
    ...properties,
    ...properties?.restInfo,
  });
};

export const trackView = (event: string, properties: any) => {
  trackEvent(event, {
    ...properties,
  });
};

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export const identifyUser = (
  properties: WithRequired<Partial<IdentifyUser>, `userId`>,
) => {
  window?.analytics?.identify(String(properties.userId), {
    ...properties,
  });
};
