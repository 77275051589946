import { studentLeadStageGroupPayload } from '@/components/SummaryDashboard/Filters/StudentDashboardFilters';
import { FetchUrgentPaymentPayload } from '@/components/SummaryDashboard/UrgentPaymentCard';
import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import { SingleApplicationType } from '@/types/application';
import { definitions, paths } from '@/types/schema';
import {
  AllApplicationResponse,
  ApplicationDocumentsResponse,
  ApplicationReadinessResponseType,
  BaseResponse,
  CheckDuplicateApiResponse,
  CommentResponse,
  CreateApplicationRequestType,
  CreateStudentLoginRequestType,
  CreateStudentLoginResponseType,
  CSPCountriesRequestType,
  CSPCountriesResponseType,
  DeleteDuplicateStudentRequestType,
  DeleteDuplicateStudentResponseType,
  DeleteStudentInfoByIdsRequestType,
  DisableStudentLoginRequestType,
  DisableStudentLoginResponseType,
  GetApplicationAuditLogsResponseType,
  GetCmsCountriesResponseType,
  GetCmsStatesByCountryResponseType,
  GetCounsellorOptions,
  GetSharedListResponseType,
  GetStudentAssignedCounsellorsResponseType,
  GetStudentOnboardingDetailsResponseType,
  SingleApplicationResponse,
  StageLevelSummaryResponse,
  StateChangeRequestType,
  StateChangeResponseType,
  StudentCourseEligibilityCheckRequestType,
  StudentCourseEligibilityCheckResponseType,
  StudentLeadSourceResponseType,
  StudentProfileStatusCheckResponseType,
  StudentStageLevelSummaryResponse,
  UpdateRemarksBasedInteractionRequestType,
  UpdateRemarksBasedInteractionResponseType,
  UpdateStudentAcademicInfoResponseType,
  UpdateStudentOnboardingDetailsRequestType,
  UpdateStudentOnboardingDetailsResponseType,
  UpdateStudentProfileThroughShortlistRequestType,
  UpdateStudentProfileThroughShortlistResponseType,
  UrgentPaymentStageLevelSummaryResponse,
} from '@/types/Services';
import { PreferredCitiesResponseType } from '@/types/vas/accomodation';
import { transformResponse } from '@/utils/service';
import { CreateStudentType } from '@/validations/createStudent.schema';

export type UpdateStudentLeadStatusResponseType =
  paths[`/lead/{studentId}/referral`]['post']['responses']['200']['schema'];
export type UpdateStudentLeadStatusRequestType =
  paths[`/lead/{studentId}/referral`]['post']['parameters']['body']['dto'];
export type GetStudentReferralLeadStatusResponseType =
  paths['/lead/{studentId}/remark']['get']['responses']['200']['schema'];
export type GetStudentReferralStatusResponseType =
  paths[`/lead/{studentId}/referral`]['get']['responses']['200']['schema'];
export type UpdateStudentPreferencesResponseType =
  paths['/v1/students/{studentId}/student-preferences']['post']['responses']['200']['schema'];
export type UpdateStudentPreferencesRequestType =
  paths['/v1/students/{studentId}/student-preferences']['post']['parameters']['body']['dto'] &
    paths['/v1/students/{studentId}/student-preferences']['post']['parameters']['path'];

type UpdateStudentPersonalInfoPath =
  paths['/v1/students/edit-personal-profile/{studentId}'];
export type UpdateStudentPersonalInfoRequestType =
  UpdateStudentPersonalInfoPath['put']['parameters']['body']['dto'] &
    UpdateStudentPersonalInfoPath['put']['parameters']['path'];
export type UpdateStudentPersonalInfoResponseType =
  UpdateStudentPersonalInfoPath['put']['responses']['200']['schema'];

export const studentApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    searchStudentsById: builder.query({
      query: (payload) => ({
        url: `/v1/students/search`,
        method: `POST`,
        body: payload,
      }),
      providesTags: [`Student`],
    }),
    getStudentInfoById: builder.query<
      definitions[`ResponseWrapper«StudentDetailsViewDto»`],
      { id: number }
    >({
      query: ({ id }) => `v1/students/${id}`,
      providesTags: [`Student`],
    }),
    fetchAllStudentRemarks: builder.query<CommentResponse, number>({
      query: (id) => `v1/students/${id}/remarks`,
      // TODO:: need to check why automatic refetch not working
      providesTags: [`Student`, `Remarks`, `documents`],
    }),
    getCspProfile: builder.query<
      definitions['ResponseWrapper«GetUserProfileCsp»'],
      void
    >({
      query: () => `/v1/csps/profile`,
    }),
    getCounsellorNameOption: builder.query<BaseResponse<any>, void>({
      query: () => `/v1/students/search/counsellor-options`,
    }),
    fetchStudentCounsellors: builder.query<GetCounsellorOptions, number>({
      query: (id) => `v1/students/${id}/counsellor-options`,
    }),
    updateStudentInfoById: builder.mutation({
      query: (payload) => ({
        url: `v1/students/${payload[1]}`,
        method: `PUT`,
        body: payload[0],
      }),
      invalidatesTags: [`Student`, `documents`, `AuditLogs`],
    }),
    updateBackgroundInfoById: builder.mutation({
      query: (payload) => ({
        url: `v1/students/${payload?.studentId}/background-information`,
        method: `POST`,
        body: payload?.body,
      }),
      invalidatesTags: [`Student`],
    }),
    fetchAllStudents: builder.mutation<BaseResponse<any>, number>({
      query: (payload: any) => ({
        url: `v1/students/search`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [`Student`],
    }),
    createStudent: builder.mutation<BaseResponse<any>, CreateStudentType>({
      query: (payload: any) => ({
        url: `v1/students`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [`Student`],
      transformResponse: transformResponse,
    }),
    //[todo] write proper types
    deleteStudentInfoById: builder.mutation<
      { data: any; error: any; message: any },
      any
    >({
      query: (payload) => ({
        url: `v1/students/${payload?.studentId}/remove/${payload?.referenceLabel}/${payload?.referenceLabelId}`,
        method: `DELETE`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            success,
            apiError: true,
          } as any;
        } else {
          return {
            success,
            data,
            message,
          } as any;
        }
      },
      invalidatesTags: [`Student`],
    }),
    searchStudent: builder.mutation<BaseResponse<any>, any>({
      query: (payload) => ({
        url: `/v1/students/search-recommendation`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [`Student`],
    }),
    loginUser: builder.query({
      query: (payload) => ({
        url: `/leap_scholar_local/login/via/credentials?app=csp_local`,
        method: `POST`,
        body: payload,
      }),
      providesTags: [`Login`],
    }),

    fetchStudentStageGroups: builder.mutation<
      StudentStageLevelSummaryResponse,
      any
    >({
      query: (payload: studentLeadStageGroupPayload) => ({
        url: `v1/students/stage-groups`,
        method: `POST`,
        body: payload,
      }),
    }),

    checkDuplicate: builder.query<
      CheckDuplicateApiResponse,
      { mobileNumber: string }
    >({
      query: ({ mobileNumber }: any) =>
        `v1/students/check-duplicate/${encodeURIComponent(mobileNumber)}`,
      providesTags: [`CheckDuplicate`],
    }),

    getPendingActions: builder.query<BaseResponse<any>, any>({
      query: ({ studentId, applicationId }) =>
        `v1/students/${studentId}/pending-actions${
          applicationId ? `?applicationId=` + applicationId : ``
        }`,
      providesTags: [`pendingActions`],
    }),

    //[todo] write proper types
    deleteStudentInfoByIds: builder.mutation<
      definitions['ResponseWrapper«boolean»'],
      DeleteStudentInfoByIdsRequestType
    >({
      query: (payload) => ({
        url: `v1/students/${payload?.studentId}/remove/${payload?.referenceLabel}`,
        method: `POST`,
        body: { referenceLabelIds: payload.referenceLabelIds },
      }),
      invalidatesTags: (_result, _error, { referenceLabel }) => {
        if (referenceLabel === `ACADEMIC_INFO`) return [`StudentExamForm`];
        if (referenceLabel === `EXAM_INFO`) return [`StudentExamForm`];
        if (referenceLabel === `WORK_EXPERIENCE`) return [`StudentWorkForm`];
        return [`Student`];
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as definitions['ResponseWrapper«boolean»'];
        } else {
          return data as unknown as definitions['ResponseWrapper«boolean»'];
        }
      },
    }),
    getStudentOnboardingDetails: builder.query<
      GetStudentOnboardingDetailsResponseType,
      number
    >({
      query: (studentId) => `/v1/students/${studentId}/details`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudentOnboardingDetailsResponseType;
        } else {
          return { data } as unknown as GetStudentOnboardingDetailsResponseType;
        }
      },
      providesTags: [`StudentOnboardingForm`],
      keepUnusedDataFor: 0,
    }),
    updateStudentOnboardingDetails: builder.mutation<
      UpdateStudentOnboardingDetailsResponseType,
      UpdateStudentOnboardingDetailsRequestType
    >({
      query: ({ data, studentId }) => ({
        url: `/v1/students/${studentId}/details`,
        method: `PUT`,
        body: { ...data },
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateStudentOnboardingDetailsResponseType;
        } else {
          return data as unknown as UpdateStudentOnboardingDetailsResponseType;
        }
      },
      invalidatesTags: [
        `StudentOnboardingForm`,
        `StudentEducationForm`,
        `StudentExamForm`,
        `Student`,
      ],
    }),
    // v1/students/{studentId}/assigned-counsellors
    getStudentAssignedCounsellors: builder.query<
      GetStudentAssignedCounsellorsResponseType,
      { studentId: number }
    >({
      query: ({ studentId }) => ({
        url: `v1/students/${studentId}/assigned-counsellors`,
        method: `GET`,
      }),
      // providesTags: [`CountryAndState`],
    }),
    updateStudentProfileFromShortlist: builder.mutation<
      UpdateStudentProfileThroughShortlistResponseType,
      UpdateStudentProfileThroughShortlistRequestType
    >({
      query: ({ studentId, data }) => ({
        url: `v1/students/${studentId}`,
        method: `PUT`,
        body: data,
      }),
    }),
    deleteDuplicateStudent: builder.mutation<
      DeleteDuplicateStudentResponseType,
      DeleteDuplicateStudentRequestType
    >({
      query: ({ studentId }) => ({
        url: `/v1/students/${studentId}/remove`,
        method: `DELETE`,
      }),
    }),
    getStudentLeadSource: builder.query<StudentLeadSourceResponseType, void>({
      query: () => ({
        url: `/v1/students/lead-source/count`,
        method: `GET`,
      }),
      transformResponse: transformResponse,
    }),
    createStudentLogin: builder.mutation<
      CreateStudentLoginResponseType,
      CreateStudentLoginRequestType
    >({
      query: ({ id, studentId }) => {
        return {
          url: `v1/csps/${id}/${studentId}/create-student-login`,
          method: `POST`,
        };
      },
      transformResponse,
      invalidatesTags: [`Student`],
    }),
    disableStudentLogin: builder.mutation<
      DisableStudentLoginResponseType,
      DisableStudentLoginRequestType
    >({
      query: ({ id, studentId }) => {
        return {
          url: `/v1/csps/${id}/${studentId}/disable-student-login`,
          method: `POST`,
        };
      },
      transformResponse,
      invalidatesTags: [`Student`],
    }),
    enableStudentLogin: builder.mutation<
      CreateStudentLoginResponseType,
      DisableStudentLoginRequestType & { newPassword: string }
    >({
      query: ({ id, studentId, newPassword }) => {
        return {
          url: `/v1/csps/${id}/${studentId}/enable-student-login`,
          method: `POST`,
          body: { newPassword },
        };
      },
      transformResponse,
      invalidatesTags: [`Student`],
    }),
    updateStudentReferralLeadStatus: builder.mutation<
      UpdateStudentAcademicInfoResponseType,
      UpdateStudentLeadStatusRequestType & { studentId: number }
    >({
      query: ({ studentId, ...payload }) => {
        return {
          url: `/lead/${studentId}/referral`,
          method: `POST`,
          body: payload,
        };
      },
      transformResponse,
    }),
    getLgStudentRemarks: builder.query<
      GetStudentReferralLeadStatusResponseType,
      { studentId: number }
    >({
      query: ({ studentId }) => ({
        url: `/lead/${studentId}/remark`,
        method: `GET`,
      }),
      transformResponse: transformResponse,
    }),
    getStudentReferralLeadStatus: builder.query<
      GetStudentReferralStatusResponseType,
      { studentId: number }
    >({
      query: ({ studentId }) => {
        return {
          url: `/lead/${studentId}/referral`,
          method: `GET`,
        };
      },
      transformResponse: transformResponse,
    }),
    updateStudentPreferences: builder.mutation<
      UpdateStudentPreferencesResponseType,
      UpdateStudentPreferencesRequestType
    >({
      query: ({ studentId, ...payload }) => ({
        url: `/v1/students/${studentId}/student-preferences`,
        body: payload,
        method: `POST`,
      }),
      transformResponse,
      invalidatesTags: [`Student`],
    }),
    updateStudentPersonalInfo: builder.mutation<
      UpdateStudentPersonalInfoResponseType,
      UpdateStudentPersonalInfoRequestType
    >({
      query: (payload) => {
        return {
          url: `/v1/students/edit-personal-profile/${payload?.studentId}`,
          body: payload,
          method: `PUT`,
        };
      },
      transformResponse,
    }),
  }),
});

//[Todo]: Add proper response typees for easy development
export const applicationApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    searchApplicationsById: builder.query<AllApplicationResponse, any>({
      query: (payload: any) => ({
        url: `v1/applications/search`,
        method: `POST`,
        body: {
          studentIds: [payload.studentId],
          sortInfo: payload.sortInfo,
          checkEligibility: payload?.checkEligibility,
          checkRaisedSop: payload?.checkRaisedSop,
          sopRequestType: payload?.sopRequestType,
        },
      }),
      providesTags: [`Application`],
    }),
    fetchApplicationAuditLogsById: builder.query<
      GetApplicationAuditLogsResponseType,
      number
    >({
      query: (applicationId) => ({
        url: `audit-logs/application/${applicationId}`,
        method: `GET`,
      }),
      providesTags: [`AuditLogs`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetApplicationAuditLogsResponseType;
        } else {
          return { data } as unknown as GetApplicationAuditLogsResponseType;
        }
      },
    }),
    fetchStageGroups: builder.mutation<StageLevelSummaryResponse, any>({
      query: (payload: any) => ({
        url: `v1/applications/stage-groups`,
        method: `POST`,
        body: payload,
      }),
    }),
    fetchUrgentPaymentStageGroups: builder.mutation<
      UrgentPaymentStageLevelSummaryResponse,
      any
    >({
      query: (payload: FetchUrgentPaymentPayload) => ({
        url: `v1/applications/payment-stage-groups`,
        method: `POST`,
        body: payload,
      }),
    }),
    fetchAllApplications: builder.mutation<AllApplicationResponse, number>({
      query: (payload: any) => ({
        url: `v1/applications/search`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [`Application`],
    }),
    fetchEachApplication: builder.query<SingleApplicationResponse, number>({
      query: (applicationId) => ({
        url: `v1/applications/${applicationId}`,
        method: `GET`,
      }),
      providesTags: [`Application`, `documents`, `SingleApplication`],
    }),
    createApplication: builder.mutation<
      definitions['ResponseWrapper«CreateApplicationCspResponseDto»'],
      CreateApplicationRequestType
    >({
      query: (payload) => ({
        url: `v1/applications`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [
        `Application`,
        { type: `Shortlist`, id: `LIST` },
        `Student`,
        `validateRemove`,
      ],
      transformResponse: transformResponse,
    }),
    updateApplicationInfoById: builder.mutation<
      BaseResponse<any>,
      { applicationId: number; data: Partial<SingleApplicationType> }
    >({
      query: ({ applicationId, data }) => ({
        url: `v1/applications/${applicationId}`,
        method: `PUT`,
        body: data,
      }),
      invalidatesTags: [`Application`],
    }),
    updateApplicationStageById: builder.mutation<
      StateChangeResponseType,
      StateChangeRequestType & { applicationId: number }
    >({
      query: ({ applicationId, ...payload }) => ({
        url: `v1/applications/${applicationId}/stage-change`,
        method: `PUT`,
        body: payload,
      }),
      invalidatesTags: [`Application`, `Student`, `AuditLogs`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as StateChangeResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as StateChangeResponseType;
        }
      },
    }),
    getApplicationRemarks: builder.query<CommentResponse, number>({
      query: (applicationId) => `applications/${applicationId}/remarks`,
      providesTags: [`ApplicationRemarks`],
    }),

    getApplicationsDocuments: builder.query<
      ApplicationDocumentsResponse,
      number
    >({
      query: (applicationId) => `v1/documents/application/${applicationId}`,
      providesTags: [`applicationDocuments`],
    }),
    deleteConditionalReasonInfoById: builder.mutation<any, any>({
      query: (data) => ({
        url: `v1/applications/${data.applicationId}/remove-conditions`,
        method: `POST`,
        body: data.payload,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as unknown as any;
        }
      },
      invalidatesTags: [`Student`],
    }),
    fetchConditionalReasons: builder.query({
      query: () => ({
        url: `shared/lists`,
        method: `GET`,
      }),
    }),
    getSharedLists: builder.query<GetSharedListResponseType, void>({
      query: () => ({
        url: `shared/lists`,
        method: `GET`,
      }),
    }),
    updateAppPriority: builder.mutation<BaseResponse<any>, any>({
      query: (payload: any) => ({
        url: `/v1/applications/set-priority`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: [`Application`],
    }),
    fetchStudentProfileStatus: builder.query<
      StudentProfileStatusCheckResponseType,
      number
    >({
      query: (studentId) => ({
        url: `v1/students/${studentId}/profile-status`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as StudentProfileStatusCheckResponseType;
        } else {
          return { data } as unknown as StudentProfileStatusCheckResponseType;
        }
      },
    }),
    checkCourseEligibility: builder.mutation<
      StudentCourseEligibilityCheckResponseType,
      StudentCourseEligibilityCheckRequestType
    >({
      query: (payload) => ({
        url: `/v1/students/${payload?.studentId}/course-eligibility-check`,
        method: `POST`,
        body: payload?.payload,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as StudentCourseEligibilityCheckResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as StudentCourseEligibilityCheckResponseType;
        }
      },
    }),
    getApplicationReadiness: builder.query<
      ApplicationReadinessResponseType,
      number
    >({
      query: (applicationId) => ({
        url: `/v1/applications/${applicationId}/readiness`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as ApplicationReadinessResponseType;
        } else {
          return { data } as unknown as ApplicationReadinessResponseType;
        }
      },
    }),
    updateRemarksBasedInteraction: builder.mutation<
      UpdateRemarksBasedInteractionResponseType,
      UpdateRemarksBasedInteractionRequestType
    >({
      query: (payload) => ({
        url: `/remarks/${payload?.remarkId}/update`,
        method: `POST`,
        body: {
          oldRemarksCategory: payload?.oldRemarksCategory,
          newremarksCategory: payload?.newremarksCategory,
          remarksResponse: payload?.remarksResponse,
        },
      }),
      invalidatesTags: [`ApplicationRemarks`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateRemarksBasedInteractionResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as UpdateRemarksBasedInteractionResponseType;
        }
      },
    }),
  }),
});
export const countryAndStateDataApi = geebeeRootBaseApiWithTags.injectEndpoints(
  {
    endpoints: (builder) => ({
      fetchAllSharedConfig: builder.query({
        query: () => ({
          url: `shared/lists`,
          method: `GET`,
        }),
        providesTags: [`CountryAndState`],
      }),
      fetchCspCountries: builder.query<
        CSPCountriesResponseType,
        CSPCountriesRequestType
      >({
        query: () => ({
          url: `v1/csps/user/countries`,
          method: `GET`,
        }),
        providesTags: [`CountryAndState`],
      }),
      fetchAllCountryData: builder.query<
        definitions['ResponseWrapper«List«string»»'],
        void
      >({
        query: () => ({
          url: `shared/countries`,
          method: `GET`,
        }),
        providesTags: [`CountryAndState`],
      }),
      fetchStatesByCountryData: builder.query<
        definitions['ResponseWrapper«List«string»»'],
        { country: string }
      >({
        query: (payload) => ({
          url: `shared/countries/${payload?.country}/state`,
          method: `GET`,
        }),
        providesTags: [`CountryAndState`],
      }),
      fetchCitiesByCountryAndStateData: builder.query<
        definitions['ResponseWrapper«List«string»»'],
        { country: string; state: string }
      >({
        query: (payload) => ({
          url: `shared/countries/${payload?.country}/state/${payload?.state}/city`,
          method: `GET`,
        }),
        providesTags: [`CountryAndState`],
      }),
      fetchAllCities: builder.query<
        PreferredCitiesResponseType,
        { country: string; city: string; returnLimit?: number }
      >({
        query: ({ country, ...rest }) => ({
          url: `shared/countries/${country}/cities`,
          method: `GET`,
          params: {
            returnLimit: 10,
            ...rest,
          },
        }),
        providesTags: [`CountryAndState`],
      }),
      fetchLanguagesData: builder.query({
        query: () => ({
          url: `shared/languages`,
          method: `GET`,
        }),
        providesTags: [`CountryAndState`],
      }),
      fetchCmsCountries: builder.query<GetCmsCountriesResponseType, void>({
        query: () => ({
          url: `shared/cms/countries`,
          method: `GET`,
        }),
        transformResponse: transformResponse,
        providesTags: [`CmsCountries`],
      }),
      fetchCmsStatesByCountry: builder.query<
        GetCmsStatesByCountryResponseType,
        { country: string }
      >({
        query: (payload) => ({
          url: `/shared/cms/countries/${payload.country}/state`,
          method: `GET`,
        }),
        transformResponse: transformResponse,
        providesTags: [`CmsStates`],
      }),
    }),
  },
);
export const {
  useGetStudentInfoByIdQuery,
  useLazyGetStudentInfoByIdQuery,
  useGetCspProfileQuery,
  useGetCounsellorNameOptionQuery,
  useLazyGetCspProfileQuery,
  useSearchStudentsByIdQuery,
  useFetchStudentStageGroupsMutation,
  useFetchAllStudentsMutation,
  useUpdateStudentInfoByIdMutation,
  useDeleteStudentInfoByIdMutation,
  useUpdateBackgroundInfoByIdMutation,
  useCreateStudentMutation,
  useSearchStudentMutation,
  useLoginUserQuery,
  useLazyCheckDuplicateQuery,
  useFetchAllStudentRemarksQuery,
  useFetchStudentCounsellorsQuery,
  useGetPendingActionsQuery,
  useDeleteStudentInfoByIdsMutation,
  useGetStudentOnboardingDetailsQuery,
  useLazyGetStudentOnboardingDetailsQuery,
  useUpdateStudentOnboardingDetailsMutation,
  useGetStudentAssignedCounsellorsQuery,
  useLazyGetStudentAssignedCounsellorsQuery,
  useUpdateStudentProfileFromShortlistMutation,
  useDeleteDuplicateStudentMutation,
  useGetStudentLeadSourceQuery,
  useCreateStudentLoginMutation,
  useDisableStudentLoginMutation,
  useEnableStudentLoginMutation,
  useUpdateStudentReferralLeadStatusMutation,
  useGetLgStudentRemarksQuery,
  useLazyGetLgStudentRemarksQuery,
  useGetStudentReferralLeadStatusQuery,
  useLazyGetStudentReferralLeadStatusQuery,
  useUpdateStudentPreferencesMutation,
  useUpdateStudentPersonalInfoMutation,
} = studentApi;

export const {
  useFetchEachApplicationQuery,
  useSearchApplicationsByIdQuery,
  useFetchAllApplicationsMutation,
  useCreateApplicationMutation,
  useUpdateApplicationInfoByIdMutation,
  useGetApplicationRemarksQuery,
  useLazyGetApplicationRemarksQuery,
  useGetApplicationsDocumentsQuery,
  useLazyGetApplicationsDocumentsQuery,
  useLazySearchApplicationsByIdQuery,
  useUpdateApplicationStageByIdMutation,
  useDeleteConditionalReasonInfoByIdMutation,
  useFetchConditionalReasonsQuery,
  useFetchUrgentPaymentStageGroupsMutation,
  useFetchStageGroupsMutation,
  useGetSharedListsQuery,
  useUpdateAppPriorityMutation,
  useFetchApplicationAuditLogsByIdQuery,
  useLazyGetApplicationReadinessQuery,
  useFetchStudentProfileStatusQuery,
  useCheckCourseEligibilityMutation,
  useLazyFetchStudentProfileStatusQuery,
  useLazyFetchEachApplicationQuery,
  useLazyGetSharedListsQuery,
  useUpdateRemarksBasedInteractionMutation,
} = applicationApi;

export const {
  useFetchAllSharedConfigQuery,
  useFetchCspCountriesQuery,
  useFetchAllCountryDataQuery,
  useFetchStatesByCountryDataQuery,
  useFetchCitiesByCountryAndStateDataQuery,
  useFetchLanguagesDataQuery,
  useLazyFetchStatesByCountryDataQuery,
  useLazyFetchCitiesByCountryAndStateDataQuery,
  useLazyFetchAllCitiesQuery,
  useFetchCmsCountriesQuery,
  useLazyFetchCmsCountriesQuery,
  useLazyFetchCmsStatesByCountryQuery,
} = countryAndStateDataApi;

export const useGetStudentInfoByIdQueryState =
  studentApi.endpoints.getStudentInfoById.useQueryState;
