import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';

import {
  BandAndUpsellBannerDto,
  ExamCategoryType,
  ExamDashBoardDto,
  ExamPreference,
  ExamResultResponseDto,
  IeltsScoreCard,
  MockTestDto,
  PbtAnswerResponse,
  PriceDTO,
  QuestionBundleDto,
  SatScoreResponse,
  TcyBannerDto,
} from '@/components/IELTSPrep/Mocktest/Interface/MockTest';

export interface MockTestReducer {
  isLoading: boolean;
  errorMessage: string;
  MockTestData: MockTestDto | null;
  examData: Array<ExamDashBoardDto>;
  submitData: boolean;
  isModalOpen: boolean;
  mockTestResultData: ExamResultResponseDto | null;
  ieltsScoreData: IeltsScoreCard[] | null;
  satScoreData: SatScoreResponse | null;
  passageText: string;
  bandAndUpsellBannerDto: BandAndUpsellBannerDto | null;
  purchaseStatus: PriceDTO | null;
  activeQuestionId: number | null;
  currentActiveMockTest: QuestionBundleDto | null;
  userAnsSheet: Record<string, any> | null;
  pbtFileAnsArray: Array<PbtAnswerResponse> | [];
  submissionLoader: boolean;
  examPref: ExamPreference | null;
  submittedExamPref: boolean;
  tcyBannerDto: TcyBannerDto | null;
  productBundles: Array<string> | null;
  writingTestAiData: any;
  examCategory?: ExamCategoryType;
}

const initialState: MockTestReducer = {
  isLoading: false,
  errorMessage: ``,
  MockTestData: null,
  examData: [],
  submitData: false,
  isModalOpen: false,
  mockTestResultData: null,
  ieltsScoreData: null,
  satScoreData: null,
  passageText: ``,
  bandAndUpsellBannerDto: null,
  purchaseStatus: null,
  activeQuestionId: null,
  currentActiveMockTest: null,
  userAnsSheet: {},
  pbtFileAnsArray: [],
  submissionLoader: false,
  examPref: null,
  submittedExamPref: false,
  tcyBannerDto: null,
  productBundles: null,
  writingTestAiData: null,
  examCategory: undefined,
};

export interface SaveMockTestDto {
  mockTestId: string;
  data: Record<string, Array<string | number>>;
  testType: string | undefined;
  submissionType: string;
  examName: string | undefined;
  examFormat: string | undefined;
  sectionId?: string | number | null;
  platform: string;
}

const mockTestSlice = createSlice({
  name: `mockTest`,
  initialState,
  reducers: {
    setMockTestIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
    setMockTestError(state, action: PayloadAction<string>) {
      return { ...state, errorMessage: action.payload };
    },
    setMockTestData(state, action: PayloadAction<MockTestDto | null>) {
      return { ...state, MockTestData: action.payload };
    },
    updateSaveMockTestStatus(state, action: PayloadAction<boolean>) {
      return { ...state, submitData: action.payload };
    },
    setIsModalOpen(state, action: PayloadAction<boolean>) {
      return { ...state, isModalOpen: action.payload };
    },
    setIeltsScoreData(state, action: PayloadAction<IeltsScoreCard[] | null>) {
      return { ...state, ieltsScoreData: action.payload };
    },
    setActiveQuestionId(state, action: PayloadAction<number | null>) {
      return { ...state, activeQuestionId: action.payload };
    },
    setCurrentActiveMockTest(
      state,
      action: PayloadAction<QuestionBundleDto | null>,
    ) {
      return { ...state, currentActiveMockTest: action.payload };
    },
    setUserAnsSheet(state, action: PayloadAction<Record<string, any>>) {
      return { ...state, userAnsSheet: action.payload };
    },
    setBandAndUpsellBannerDto(
      state,
      action: PayloadAction<BandAndUpsellBannerDto | null>,
    ) {
      return { ...state, bandAndUpsellBannerDto: action.payload };
    },
    setWritingTestAiData(state, action: PayloadAction<any>) {
      return { ...state, writingTestAiData: action?.payload };
    },
    setExamCategory(state, action: PayloadAction<any>) {
      return { ...state, examCategory: action?.payload };
    },
  },
});

export const fetchMockTestDataAction = createAction<string>(
  `mockTest/fetchMockTestData`,
);

export const fetchMockTestResultDataAction = createAction<string>(
  `mockTest/fetchMockTestResultDataAction`,
);

export const submitMockTestData =
  createAction<SaveMockTestDto>(`mockTest/submit`);

export const fetchPurchaseStatusAction = createAction(
  `mockTest/purchaseStatus`,
);

export const fetchIeltsScoreAction = createAction<string>(
  `mockTest/ieltsScoreCard`,
);

export const {
  setMockTestIsLoading,
  setMockTestError,
  setMockTestData,
  updateSaveMockTestStatus,
  setIsModalOpen,
  setIeltsScoreData,
  setActiveQuestionId,
  setCurrentActiveMockTest,
  setUserAnsSheet,
  setBandAndUpsellBannerDto,
  setWritingTestAiData,
  setExamCategory,
} = mockTestSlice.actions;

export default mockTestSlice.reducer;
