import { transformResponse } from '@/utils/service';

import { paths } from '../types/schema';

import { geebeeRootBaseApiWithTags } from './base/geebeeRoute';

export type RewardCreditedEventTypeResponse =
  paths[`/users/reward-credited-event-types`][`get`][`responses`][`200`][`schema`];

export const rewardsCreditedApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRewardCreditedEvent: builder.query<
      RewardCreditedEventTypeResponse,
      void
    >({
      query: () => `/users/reward-credited-event-types`,
      transformResponse,
    }),
  }),
});

export const { useGetRewardCreditedEventQuery } = rewardsCreditedApi;
