export enum localStorageKeys {
  studentDashboardFilters = `student-summary-dashboard-filters-1`,
  summaryDashboardFilters = `summary-dashboard-filters-1`,
  STI_TAT = `sti-tat`,
  SPEAKING_TEST_ID = `speaking-test-id`,
  PRE_AUTH_LEAD_STAGES_VIEWED = `pre-auth-lead-stages-viewed`,
  APPLICATION_TAT_MODAL_DISPLAY_COUNT_MAPPER = `application-tat-modal-display-count`,
  SHORLISTS_TAT_MODAL_DISPLAYED_LIST = `shortlists-tat-modal-displayed-list`,
  COMMISSION_STRUCTURE_MODAL = `commission-structure-modal-dates`,
  LAST_POPUP_TIMES = `lastPopupTimes`,
  TOKEN = `token`,
  SHOW_LOANS_UPSELL_COUNT = `show-loans-upsell-count`,
  ENABLE_IELTS_BANNER_DISPLAYED = `enable-ielts-banner-displayed`,
  IELTS_ADOPTION_POPUP_DISPLAYED = `ielts-adoption-popup-displayed`,
  IELTS_POPUP_DISPLAYED_TIME = `ielts-popup-displayed-time`,
  SHOW_LOAN_MELA_AWARENESS = `show-loan-mela-awareness`,
  SHOW_EUROPE_COUNSELLING_UPSELL = `show-europe-counselling-upsell`,
  SHOW_EUROPE_COUNSELLING_UPSELL_V2 = `show-europe-counselling-upsell-v2`,
  SHOW_EUROPE_COUNSELLING_UPSELL_CF = `show-europe-counselling-upsell-cf`,
  ADPOPUP_NOV_24_PAYMENT_DRIVE = `adpopup-nov-24-payment-drive`,
  IELTS_LIVE_DEMO_POPUP = `ielts-live-demo-popup`,
  OFFER_STAGE_STUDENTS_MODAL = `offer-stage-students-modal`,
  DUOLINGO_VOUCHER_MODAL = `duolingo-voucher-modal`,
  IELTS_LIVE_JAN_DRIVE = `ielts-live-demo-jan-drive`,
}

export class GBLocalStorageUtil {
  /**
   * Reads a value from localStorage based on a specified key from localStorageKeys constant.
   *
   * @param {keyof typeof localStorageKeys} key - The key corresponding to a localStorage item in the localStorageKeys object.
   * @returns {string | null} The data stored in localStorage under the specified key, or null if the key does not exist. If data exists, it's parsed and then returned.
   * @logs {Error} If there is an issue accessing the localStorage.
   *
   * @example
   * // Read data associated with 'commission-structure-modal-dates' key
   * const data = handleReadLocalStorageData('COMMISSION_STRUCTURE_MODAL'); // This will return the parsed data if available, else returns null.
   * console.log(data);
   */
  static handleReadLocalStorageData = (key: localStorageKeys) => {
    try {
      const data = localStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      } else {
        return null;
      }
    } catch (error) {
      console.log(`Error in reading local storage data in hook`, error);
      return null;
    }
  };

  /**
   * Set a value to localStorage based on a specified key from localStorageKeys constant.
   *
   * @param {keyof typeof localStorageKeys} key - The key corresponding to a localStorage item in the localStorageKeys object.
   * @param {any} data - This is the data to be set to localstorage, it'll be strigified inside this function. If data is a JS falsy value, function will be skipped
   * @returns {boolean} True if data is set successfully, false if it's a failure
   * @logs {Error} If there is an issue accessing the localStorage.
   */
  static handleSetLocalStorageData = (key: localStorageKeys, data: any) => {
    try {
      const stringifiedData = JSON.stringify(data);
      localStorage.setItem(key, stringifiedData);
      return true;
    } catch (error) {
      console.log(`Error in setting local storage data in hook`, error);
      return false;
    }
  };

  /**
   * Removes a value from localStorage based on a specified key from localStorageKeys constant.
   *
   * @param {keyof typeof localStorageKeys} key - The key corresponding to a localStorage item in the localStorageKeys object.
   * @returns {boolean} True if data is removed successfully, false if it's a failure
   * @logs {Error} If there is an issue accessing the localStorage.
   */
  static handleRemoveLocalStorageData = (key: localStorageKeys) => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.log(`Error in removing local storage data in hook`, error);
      return false;
    }
  };
}
